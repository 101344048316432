.profile-content-div {
  background-color: white;
  height: 84vh;
  width: 74vw;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2rem;
  gap: 24px;
}

.report-setting-root {
  height: 100%;
  width: 100%;
}

.report-setting-grid {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns: 50% 50%;
  gap: 20px 10px;
  grid-template-areas:
    "setting spec";
}

.report-setting-setting-grid {
  grid-area: setting;
  height: 100%;
  overflow-y: scroll;
}

.report-setting-spec-grid {
  grid-area: spec;
  height: 100%;
  overflow-y: scroll;
}

.report-setting-wrapper {
  display: flex;
  flex-direction: column;
  padding: 40px;
  height: 100%;
  width: auto;
  gap: 24px;
  overflow-y: scroll;
}

.report-setting-form {
  display: flex;
  flex-direction: column;
  margin-top: 18px;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  grid-template-areas:
    "setting spec";
    
}

.report-setting-spec {
  display: flex;
  flex-direction: column;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  grid-template-areas:
    /* "companyName companyLogo"
    "phone phone"
    "mail mail" 
    "submit submit"; */
    "setting spec";
    
}

.report-setting-form-title-text{
  padding-bottom: 12px;
  font-weight: 500;
}

.report-setting-form-input-company-name {
  grid-area: companyName;
}

.report-setting-form-input-company-logo {
  grid-area: companyLogo;
}

.report-setting-form-input-phone {
  grid-area: phone;
}

.report-setting-form-input-mail {
  grid-area: mail;
}

.report-setting-spec-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: auto;
  overflow-y: scroll;
  gap: 24px;
}


.report-setting-form-submit {
  text-align: center;
  grid-area: submit;
}

.report-setting-update-button {
  background-color: #141a45;
  color: white;
  /* font-size: 20px; */
  padding: 5px 60px;
  border-radius: 5px;
  margin: 10px 0px;
  cursor: pointer;
  border: none;
}

.report-setting-update-button:disabled {
  background-color: gray;
  color: #ccc;
  cursor: not-allowed;
}

.report-setting-form-input-company-logo-upload-button{
  background-color: white;
  color: #575757;
  padding: 5px 40px;
  border-radius: 5px;
  margin: 10px 0px;
  cursor: pointer;
  border: 2px solid #575757; /* 枠線の追加 */

}

.report-setting-form-input-company-logo-delete{
  padding-top: 12px;
  text-decoration: underline;
  cursor: pointer;
}

/* 送信中のスタイル */
.report-setting-update-button.loading {
  background-color: #808080; /* 例: グレー */
  cursor: not-allowed;
}

/* 成功時のスタイル */
.report-setting-update-button.success {
  background-color: #4caf50; /* 例: 緑 */
}

/* エラー時のスタイル */
.report-setting-update-button.error {
  background-color: #f44336; /* 例: 赤 */
}

.no-logo-box {
  width: 100px;
  height: 100px;
  border: 1px dashed #000;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}

/* 削除オプションのボタンスタイル */
.delete-options {
  margin-top: 10px;
  /* 他のスタイリングプロパティを追加できます */
}

/* Deleteボタンのスタイル */
.delete-options button {
  background-color: #f44336; /* 赤色の背景色 */
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 5px;
  /* 他のスタイリングプロパティを追加できます */
}

/* Cancelボタンのスタイル */
.delete-options button.cancel-button {
  background-color: #ccc; /* グレーの背景色 */
  color: #333;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  /* 他のスタイリングプロパティを追加できます */
}

.report-setting-update-button {
  background-color: #141a45;
  color: white;
  padding: 5px 60px;
  border-radius: 5px;
  margin: 10px 0px;
  cursor: pointer;
  border: none;
}

.report-setting-update-button:disabled {
  background-color: gray;
  color: #ccc;
  cursor: not-allowed;
}

.button-container {
  display: flex;
  gap: 10px; /* ボタン間の間隔を調整 */
  align-items: center; /* ボタンを垂直方向に中央に配置 */
}

.button-container button {
  background-color: #808080; /* デフォルトはグレー */
  color: #ccc;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
}

.button-container button.selected {
  background-color: #141a45; /* 選択されたボタンの色 */
  color: white;
}
.bolder-text {
  font-weight: bold; /* テキストを太字にする */
  /* 他のスタイリングプロパティを追加できます */
}