.clientInfo-root {
  flex-direction: column;
}

.clientInfo-wrapper {
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 92vh;
  width: 100vw;
  padding: 2rem;
  /* background-color: red; */
}
.clientInfo-content-div {
  background-color: white;
  height: 84vh;
  width: 100%;
  /* margin: 2rem; */
  border-radius: 24px;
  display: flex;
  flex-direction: row;
}

.clientInfo-options-container {
  margin-top: 36px;
  margin-bottom: 36px;
  margin-left: 36px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
  padding-right: 12px;
  border-right: #eee solid 2px;
}
.clientInfo-option-div {
  width: 164px;
  height: 40px;
  /* background-color: #eff1ff;
  background-color: #141a45; */

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-weight: 500;
  cursor: pointer;
  /* color: white; */
}

.clientInfo-option-div-selected {
  width: 164px;
  height: 40px;
  background-color: #eff1ff;
  background-color: #141a45;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-weight: 500;
  color: white;
  cursor: pointer;
}

.clientInfo-option-content {
  width: 100%;
}
