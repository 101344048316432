.clientCredentials-root {
  /* background-color: red; */
  height: 100%;
  width: 100%;
}
.clientCredentials-wrapper {
  height: auto;
  width: auto;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
}

.heading-text {
  font-size: 22px;
  color: #575757;
  font-weight: 500;
}
