.profile-content-div {
  background-color: white;
  height: 84vh;
  width: 74vw;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2rem;
  gap: 24px;
}
