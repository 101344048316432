.account-setup-root {
  background-color: white;
  /* margin: 8vw 8vh; */
  /* margin-top: 12vh;
  margin-bottom: 8vh;
  margin-left: 4vw;
  margin-right: 4vw; */
  height: 92%;
  width: 92%;
  border-radius: 24px;
  /* padding: 4vw 4vh; */
}

.account-setup-flexbox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4vw;
  height: 100%;
}
.account-setup-image {
  width: 40vw;
  transition: 400ms;
}
/* .account-setup-image:hover {
  width: 36vw;
} */

.account-setup-formbox {
  width: 40vw;
  display: flex;
  flex-direction: column;
  padding: 2vw 2vh;
  align-items: center;
  justify-content: center;
  gap: 16px;
  transition: 400ms;
}

.form-heading {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
  transition: 400ms;
}
.form-heading:hover {
  gap: 16px;
}

.form-heading-text {
  font-size: 22px;
  color: #747474;
  font-weight: 400;
}
.form-heading-text-small {
  font-size: 16px;
  color: #575757;
  font-weight: 500;
}

.settings-input-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1rem;
  align-items: center;
  /* padding-top: 1.2rem; */
}
.normal-input {
  width: 24rem;
  height: 2.8rem;
  border-radius: 0.8rem;
  border: 2px solid #ccc;
  padding: 0rem 0.8rem;
}
.main-button-blue {
  background-color: #282860;
  color: white;
  width: 26rem;
  height: 2.8rem;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  transition: 200ms;
  cursor: pointer;
}

.main-button-green {
  background-color: #28602d;
  color: white;
  width: 26rem;
  height: 2.8rem;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  transition: 200ms;
  cursor: pointer;
}

.main-button-blue:hover {
  background-color: #333f9b;
}

.secondary-button-blue {
  background-color: #282860;
  color: white;
  /* width: 26rem; */
  padding-left: 28px;
  padding-right: 28px;
  height: 2.8rem;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  /* font-weight: 500; */
  transition: 200ms;
  cursor: pointer;
}
.secondary-button-blue:hover {
  background-color: #333f9b;
}
.main-button-red {
  background-color: #a03c2d;
  color: white;
  width: 26rem;
  height: 2.8rem;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  transition: 200ms;
  cursor: pointer;
}

.main-button-red:hover {
  background-color: #7a2f24;
}

.footer-text-box {
  flex-direction: row;
}

.info-text {
  color: #777;
}

.link-text {
  color: #4555cc;
  font-weight: 500;
  cursor: pointer;
}

.error-text-small {
  color: red;
  font-size: 12px;
}
