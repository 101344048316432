.job-detail-root {
  height: 100%;
  width: 100%;
  /* border: 1px solid yellow; */
}

.job-detail-wrapper {
  display: flex;
  flex-direction: column;
  padding: 40px;
  height: 100%;
  width: auto;
  gap: 24px;
  overflow-y: scroll;
  align-items: center;
  /* border: 1px solid green; */
}

.job-detail-content {
  background-color: white;
  height: 84vh;
  width: 74vw;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  /* border: 1px solid blue; */
}

.job-detail-items {
  display: grid;
  height: 50%;
  width: 100%;
  gap: 20px 20px;
  grid-template-areas:
    "video-id job-status job-status-reason"
    "solution solution solution";
  /* border: 1px solid red; */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* ドロップシャドウを追加 */
  padding: 20px;
  overflow: auto; 
}

.job-detail-video-video-id {
  grid-area: video-id;
  /* border: 1px solid #000; */
}

.job-detail-video-job-status {
  grid-area: job-status;
  /* border: 1px solid #000; */
}

.job-detail-video-job-status-reason {
  grid-area: job-status-reason;
  /* border: 1px solid #000; */
}

.job-detail-video-job-solution {
  grid-area: solution;
  /* border: 1px solid #000; */
}

.job-detail-item-title {
  background-color: #f0f0f0; /* タイトルの背景色 */
  padding: 10px; /* タイトルの内部パディング */
  font-weight: bold; /* タイトルのテキストを太字にする */
  border-bottom: 1px solid #ddd; /* タイトルの下にボーダーラインを追加 */
}

.job-detail-item-content {
  padding: 10px; /* コンテンツの内部パディング */
  border-bottom: 1px solid #eee; /* コンテンツの下にボーダーラインを追加 */
}