.api-reference-root {
  display: flex;
  flex-direction: column;
  /* background-color: red; */
  height: 100%;
  width: 100%;
  /* align-items: center;
  justify-content: center; */
}
.api-reference-wrapper {
  display: flex;
  flex-direction: column;
  /* background-color: blue; */
  padding: 40px;
  /* margin: 40px 40px; */
  height: fit-content;
  width: auto;
  gap: 24px;
  overflow-y: scroll;
}
.simple-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
}

.table-row {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: flex-start;
  gap: 24px;
  width: 100%;
}

.table-entry-one {
  flex: 1;
  text-align: start;
}
.table-entry-two {
  flex: 1;
  text-align: start;
}
.table-entry-three {
  flex: 3;
  text-align: start;
}
.table-entry-four {
  flex: 4;
  text-align: start;
}

.description-table {
  border-radius: 8px;
  background-color: #575757;
}

.documentation-section {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.code-text {
  font-family: monospace;
  white-space: pre-wrap;
}
.bolder-text {
  font-weight: 500;
  font-size: 14px;
}
.bold-text {
  font-weight: 600;
  font-size: 18px;
}
.code-block {
  padding: 22px;
  text-align: start;
  /* align-items: center; */
  text-emphasis-color: black;

  background-color: #575757;
  color: white;
  font-size: 14px;
  display: flex;
  /* align-items: center; */
  border-radius: 8px;
  flex-direction: column;
  gap: 22px;
}
.code-block ::selection {
  background-color: white;
  color: #575757;
}

.view {
  border: red solid 1px;
}

.table-block {
  padding: 22px;
  /* text-align: start; */
  align-items: flex-start;
  text-emphasis-color: black;

  background-color: #ddd;
  /* color: black; */
  font-size: 14px;
  display: flex;
  /* align-items: center; */
  border-radius: 8px;
  flex-direction: column;
  gap: 22px;
}
.table-block ::selection {
  background-color: white;
  color: #575757;
}

::selection {
  color: white;
  /* color: #e5e5ff; */

  background-color: white;
  background-color: #282860;
}

.info-text-small {
  font-size: 12px;
  white-space: pre-wrap;
}
