.webhook-setting-root {
  height: 100%;
  width: 100%;
}

.webhook-setting-wrapper {
  display: flex;
  flex-direction: column;
  padding: 40px;
  height: 100%;
  width: auto;
  gap: 24px;
  /* overflow-y: scroll; */
}

.webhook-setting-form {
  /* display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  grid-template-areas:
    "companyName"
    "companyLogo"
    "submit"; */
    display: flex;
  flex-direction: column;
  padding-top: 40px;
  height: 100%;
  width: auto;
  gap: 24px;
}

.webhook-setting-form-title-text{
  padding-bottom: 12px;
  font-weight: 500;
}

.webhook-setting-form-input-company-name {
  /* grid-area: companyName; */
}

.webhook-setting-form-input-company-logo {
  /* grid-area: companyLogo; */
}

.webhook-setting-form-submit {
  padding-top: 36px;
  text-align: center;
  grid-area: submit;
}

.webhook-setting-update-button {
  background-color: #141a45;
  color: white;
  /* font-size: 20px; */
  padding: 5px 60px;
  border-radius: 5px;
  margin: 10px 0px;
  cursor: pointer;
  border: none;
}

.webhook-setting-update-button:disabled {
  background-color: gray;
  color: #ccc;
  cursor: not-allowed;
}

.webhook-setting-nw-check-button{
  background-color: white;
  color: #575757;
  padding: 5px 20px;
  border-radius: 5px;
  margin: 10px 0px;
  cursor: pointer;
  border: 2px solid #575757; 
}

.webhook-setting-nw-check-button.loading {
  background-color: #808080;
  cursor: not-allowed;
}

.webhook-setting-nw-check-button.success {
  background-color: #4caf50;
  color: white;
  border: none;
}

.webhook-setting-nw-check-button.error {
  background-color: #f44336;
  color: white;
  border: none;
}
.webhook-setting-form-input {
  width: 24rem;
  height: 2.8rem;
  border-radius: 0.8rem;
  border: 2px solid #ccc;
  padding: 0rem 0.8rem;
  margin-right: 12px;
}

.webhook-setting-form-input-company-logo-delete{
  padding-top: 12px;
  text-decoration: underline;
}

/* 送信中のスタイル */
.webhook-setting-update-button.loading {
  background-color: #808080; /* 例: グレー */
  cursor: not-allowed;
}

/* 成功時のスタイル */
.webhook-setting-update-button.success {
  background-color: #4caf50; /* 例: 緑 */
}

/* エラー時のスタイル */
.webhook-setting-update-button.error {
  background-color: #f44336; /* 例: 赤 */
}

.no-logo-box {
  width: 200px;
  height: 200px;
  border: 1px dashed #000;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}

.webhook-setting-grid{
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns: 50% 50%;
  gap: 20px 10px;
  grid-template-areas: 
  "areaA areaB";
}

.webhook-setting-form-grid{
  grid-area: areaA;
}

.webhook-setting-webhook-spec-grid{
  grid-area: areaB;
  overflow-y: scroll;
  margin-bottom: 40px;
}

.webhook-setting-webhook-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: auto;
  gap: 24px;
  
}

.webhook-setting-info-icon {
  position: relative;
  display: inline-block;
  margin-left: 6px;
}

.webhook-setting-info-tooltip {
  display: none;
  position: absolute;
  background-color: #141a45;
  color: white;
  border-radius: 4px;
  padding: 8px;
  font-size: 12px;
  width: 150px;
  text-align: center;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.webhook-setting-info-icon:hover .webhook-setting-info-tooltip {
  display: block;
}