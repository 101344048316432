.video-logs-wrapper {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;

  word-break: break-all;
}

.files-dropzone {
  border: dashed #ccc 2px !important;
  padding: 5rem 10rem;
}

.manual-upload-metadata {
  display: flex;
  flex-direction: column;
}

.remove-file {
  cursor: pointer;
}

.manual-upload-button {
  background-color: #141a45;
  color: white;
  /* font-size: 20px; */
  padding: 5px 60px;
  border-radius: 5px;
  margin: 10px 0px;
  cursor: pointer;
  border: none;
}
