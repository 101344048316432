.topbar-root {
  width: 100%;
  background-color: white;
  height: 8vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* padding-left: 24px; */
  /* padding-right: 24px; */
  align-items: center;
}

.topbar-icon-div {
  margin-left: 32px;
}

.topbar-tabs-div {
  display: flex;
  flex-direction: row;
  margin-right: 32px;
  justify-content: center;
  gap: 16px;
  align-items: center;
}
.tab-title-text {
  font-weight: 500;
}

.selected-tab {
  background-color: #141a45;
  border-radius: 18px;
  color: white;
  padding: 8px 16px;
}

.unselected-tab {
  background-color: white;
  border-radius: 18px;
  padding: 8px 16px;
  transition: 400ms;
}
.unselected-tab:hover {
  background-color: #e2e5fb;
}
