.manual-upload-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.files-dropzone {
  border: dashed #ccc 2px !important;
  padding: 1rem;
  width: 20%;
  height: 10rem;
  text-align: center;
}

.manual-upload-metadata {
  display: flex;
  flex-direction: row;
  /* width: 100%; */
}

.remove-file {
  cursor: pointer;
}

.manual-upload-button {
  background-color: #141a45;
  color: white;
  /* font-size: 20px; */
  padding: 5px 60px;
  border-radius: 5px;
  margin: 10px 0px;
  cursor: pointer;
  border: none;
}

.manual-upload-button.loading {
  background-color: #808080; /* グレーなどの色に変更 */
  cursor: not-allowed; /* カーソルを無効に */
}

.manual-upload-button.success {
  background-color: #4caf50; /* 成功時の背景色 */
  cursor: not-allowed; /* カーソルを無効に */
}


.manual-upload-button.error {
  background-color: #f44336; /* エラー時の背景色 */
  cursor: not-allowed; /* カーソルを無効に */
}


.manual-upload-sub-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 40px;
}

.manual-upload-upload-wrapper {
  width: 60%;
}

.input-blocks {
  width: 100%;
}

.add-file-button {
  background-color: #575757;
  color: white;
  padding: 5px 60px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
}

.file-metadata-block {
  background-color: #ddd;
  border-radius: 10px;
  padding: 1rem 1rem;
  margin: 1rem 0rem;
}

.files-upload{
  display: grid;
  width: 100%;;
  grid-template-columns: auto auto;
  gap: 20px 40px;
  grid-template-areas: 
  "areaA areaB"
  "areaC areaD";
}

.video-upload{
  grid-area: areaA;
}

.resume-upload{
  grid-area: areaB;
}

.jd-upload{
  grid-area: areaC;
}

.qna-upload{
  grid-area: areaD;
}

.upload-footer{
  display: grid;
  width: 100%;
  grid-template-columns: 80% 20%;
  grid-template-areas: 
  "fileName progress";
}

.upload-file-name{
  grid-area: fileName;
}

.upload-progress{
  grid-area: progress;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 8px;
}